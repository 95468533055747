// libs
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

// constants
import Codes from '../constants/code'
import { goToLogin } from '../utils'

const { message } = window as any

const { REACT_APP_APPLET_PREFIX, NODE_ENV } = process.env
const isDev = NODE_ENV === 'development'
const appletPrefix = isDev ? '' : REACT_APP_APPLET_PREFIX

function catchUnauthorized(response: AxiosResponse<any>) {
  const { pathname } = window.location
  if (/\/signin$/.test(pathname)) {
    // 登录页抓取数据不做401跳转
    return Promise.reject(response)
  }
  goToLogin(appletPrefix)
  return Promise.reject(response)
}

function requestError(error: any) {
  // eslint-disable-next-line
  console.log(error)
  return Promise.reject(error)
}

function afterReceiveResponse(response: AxiosResponse<any>) {
  const { data } = response
  const { code } = data

  if (code && code > 0) {
    switch (code) {
      case Codes.SERVER_UNAUTHORIZED:
        return catchUnauthorized(response)
      case Codes.SERVER_RUNTIME_ERROR:
        message.warning('系统出错，请重试或联系系统管理员')
        break
      case Codes.SERVER_OPERATE_REJECT:
        message.warning(data.message || '操作被拒绝，请重试或联系系统管理员')
        break
      case Codes.SERVER_FORBIDDEN:
        message.warning(data.message || '您没有权限执行该操作')
        break
      default:
        message.warning(data.message)
        break
    }

    return Promise.reject(response)
  }

  return response
}

export const XMLConfig = {
  headers: {
    'content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: isDev,
}

const AXIOS_DEFAULT_CONFIG = {
  ...XMLConfig,
}

const managerAPI = axios.create({
  // baseURL: '/mapi',
  ...AXIOS_DEFAULT_CONFIG,
})

managerAPI.interceptors.response.use(afterReceiveResponse, requestError)

function logout() {
  const url = isDev
    ? '/accountTest/api/account/logout'
    : '/account/api/account/logout'
  return managerAPI.post(url)
}

function login({ code, pwd }: { code: string; pwd: string }) {
  const url = isDev
    ? '/accountTest/api/account/login'
    : '/account/api/account/login'
  return managerAPI.post(url, { code, pwd, linghe: 1 })
}

export default {
  get: (prefix: string) => <T>(url: string, config?: AxiosRequestConfig) =>
    managerAPI.get<T>(`${prefix}${url}`, config),
  post: (prefix: string) => <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => managerAPI.post<T>(`${prefix}${url}`, data, config),
  logout,
  login,
}
