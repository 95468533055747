// lib
import React from 'react'

// components

// styles
import styles from './SignContainer.module.less'

import env from '../../constants/env'

const { REACT_APP_LOGO_BLUE, REACT_APP_HOST_NAME } = env

type Props = React.PropsWithChildren<any>

export default function SignContainer({ success, form, footer }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        {success || (
          <div className={styles.form}>
            <div className={styles.header}>
              <img src={REACT_APP_LOGO_BLUE} alt={REACT_APP_HOST_NAME} />
              <h1>{REACT_APP_HOST_NAME}小程序</h1>
            </div>
            {form}
            {footer ? (
              <div className={styles['form-footer']}>{footer}</div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}
