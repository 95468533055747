import React from 'react'
import ReactDOM from 'react-dom'
import dayjs from 'dayjs'
// eslint-disable-next-line
import { AppContainer, hot } from 'react-hot-loader'
import { ConfigProvider, message } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')

window.message = message

// 多页应用入口通用render函数
export default function Render(App) {
  const RHLComponent = hot(module)(App)

  ReactDOM.render(
    <AppContainer>
      <ConfigProvider locale={zhCN}>
        <RHLComponent />
      </ConfigProvider>
    </AppContainer>,
    document.getElementById('root')
  )
}
