// /** 未登录异常码 */
// NOT_LOGIN_CODE = 1001;
// /** 未授权异常码 */
// UNAUTHORIZED_CODE = 1002;
// /** 用户不可用异常码 */
// UNUSED_CODE = 1003;

// /** 请求参数未找到异常码 */
// PARAM_NOT_FOUND_CODE = 4001;
// /** 请求参数校验错误异常码 */
// PARAM_VALIDATA_CODE = 4002;

// /** 请求数据未找到异常码 */
// DATA_NOT_FOUND_CODE = 5001;
// /** 程序计算异常码 */
// RUN_ERROR_CODE = 5002;
// /** 计算被拒绝异常码 */
// OPT_REJECT_CODE = 5003;

// /** 配置信息未找到异常码 */
// NOT_FOUNE_CODE = 6001;
// /** 配置错误异常码 */
// CONFIG_ERROR_CODE = 6002;

export default {
  /**
   * @description 未认证/未登录
   */
  SERVER_UNAUTHORIZED: 1001,

  /**
   * @description 未授权/访问拒绝
   */
  SERVER_FORBIDDEN: 1002,

  /**
   * @description 访问拒绝
   */
  SERVER_INVALID_USER: 1003,

  /**
   * @description 资源不存在/被删除
   */
  SERVER_NOT_FOUND: 5001,

  /**
   * @description 运行时异常
   */
  SERVER_RUNTIME_ERROR: 5002,

  /**
   * @description 操作被拒绝
   */
  SERVER_OPERATE_REJECT: 5003,
} as Record<string, number>
