import env from '../constants/env'

import qs from 'query-string'

export { v4 as uuidv4 } from 'uuid'

const {
  REACT_APP_INJECT_AVATAR_PREFIX: PREFIX,
  REACT_APP_DEFAULT_AVATAR: DEFAULT_AVATAR,
} = env

const URL_REGX = /^(https?:)?\/\//i

const BASE64_REGX = /^data:.+base64,/i

export const URL_REG = /(http|https):\/\/([\w.]+\/?)\S*/

/**
 * @description 格式化头像地址和尺寸
 * @param {string} url - 图片地址(objectKey|base64|complete url)
 */
export const formatAvatar = (
  url = DEFAULT_AVATAR,
  {
    width,
    height,
  }: {
    width?: number
    height?: number
  } = {}
) => {
  let wholeUrl = url || DEFAULT_AVATAR
  // 非完整url或者base64图片资源，理解为项目内oss上传的图片
  if (!URL_REGX.test(wholeUrl!) && !BASE64_REGX.test(wholeUrl!)) {
    wholeUrl = `${PREFIX}${url}`
  }

  if (
    !BASE64_REGX.test(wholeUrl!) &&
    ((width && width > 0) || (height && height > 0))
  ) {
    return [
      wholeUrl,
      wholeUrl!.indexOf('?') > -1 ? '&' : '?',
      'x-oss-process=image/resize,',
      width ? `w_${width}` : '',
      height ? `h_${height}` : '',
    ].join('')
  }

  return wholeUrl as string
}

// applet {name: 'xu', age: 18}
export const getLocalStorage = (name = 'applet') => {
  const str = window.localStorage.getItem(name) || '{}'
  const params = JSON.parse(str)
  return params
}

export const setLocalStorage = (key: string, value: any, name = 'applet') => {
  const params = { ...getLocalStorage(name), [key]: value }
  window.localStorage.setItem(name, JSON.stringify(params))
}
;(window as any).setLocalStorage = setLocalStorage

// 链式异步调用
export function promiseChain<T>(
  arr: T[] = [],
  promiseCallback: (item: T) => any
) {
  const copy = arr.slice()
  let count = -1

  async function nextTick(
    resolve: (value?: void | PromiseLike<void> | undefined) => void,
    reject: (reason?: any) => void
  ) {
    count += 1
    const next = copy[count]
    if (count >= copy.length) {
      resolve()
      return
    }

    try {
      await promiseCallback(next)
      nextTick(resolve, reject)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      reject()
    }
  }

  return new Promise<void>(function chain(resolve, reject) {
    nextTick(resolve, reject)
  })
}

const tenPower = (power = 2) => (str: string | number = 0) =>
  parseInt(
    parseFloat(`${str}`)
      .toFixed(power)
      .replace('.', ''),
    10
  )

export const yuan2fen = tenPower(2)

// 加
export const sumFloat = (power = 2) => (...args: Array<string | number>) => {
  // 1.113 + 1.114 = 2.227 -> 2.23 正确
  // 不给 power 加 1 算出结果就是 2.22
  const newPower = power + 1
  const toInt = tenPower(newPower)
  const value =
    args.reduce<number>((total, next) => total + toInt(next), 0) /
    10 ** newPower
  return value.toFixed(power)
}

// 乘
export const productFloat = (power = 2) => (
  ...args: Array<string | number>
) => {
  const newPower = power + 1
  const toInt = tenPower(newPower)
  const value =
    args.reduce<number>((total, next) => total * toInt(next), 1) /
    10 ** (newPower * args.length)
  return value.toFixed(power)
}

export const getFormLayout = (labelCol: number, wrapperCol: number) => ({
  labelCol: {
    span: labelCol,
  },
  wrapperCol: {
    span: wrapperCol,
  },
})

export function isEmpty(val: any) {
  return val === undefined || val === null || val === ''
}

export function ifEmpty(val: any, placeholder: any) {
  return isEmpty(val) ? placeholder : val
}

export const parseFileName = (name: string) => {
  const matchArr = name.match(/^(.+)\.(.+)$/)
  if (matchArr) {
    const [, filename, suffix] = matchArr
    return [filename, suffix]
  }
  return [name]
}

export function goToLogin(prefix = '') {
  const { pathname, search } = window.location
  const originalUrl = encodeURIComponent(pathname + search)
  window.location.replace(`${prefix}/signin?originalUrl=${originalUrl}`)
}

export function guid() {
  function r(size = 4) {
    return Math.random()
      .toString(36)
      .substr(2, size)
  }

  return `${r(8)}-${r()}-${r()}`
}

export function leftPad(num: number | string) {
  return Number(num) > 9 ? num : `0${num}`
}

export function setInputSelection(
  input: HTMLInputElement,
  startPos: number,
  endPos: number
) {
  input.focus()
  if (typeof input.selectionStart !== 'undefined') {
    // eslint-disable-next-line no-param-reassign
    input.selectionStart = startPos
    // eslint-disable-next-line no-param-reassign
    input.selectionEnd = endPos
  }
}

/**
 * 序列化查询参数
 * @param params
 */
export function stringifyQuery(params?: Record<string, any>) {
  if (!params) {
    return ''
  }
  return qs.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  })
}

export const DATE_FORMAT = {
  DATE: 'YYYY-MM-DD',
  DATE_TIME: 'YYYY-MM-DD HH:mm',
}

export * from './_danger'
