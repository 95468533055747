// libs
import React, { useContext, useEffect, useState } from 'react'

import { XMLConfig } from '../request'
import AuthCode from '../constants/code'
import { goToLogin } from '../utils'

interface EnvType {
  projectPrefix: string
  projectName?: string
  currentUser: AppletUserVO
  pageCode?: number
  setPageCode?: (error: any | number) => void
  clearPageCode?: () => void
  projectRoles?: string[]
  setProjectRoles?: DispatchState<string[]>
}

const EnvContext = React.createContext<EnvType>({
  projectPrefix: '',
  projectName: '',
  currentUser: {} as AppletUserVO,
  pageCode: undefined,
  setPageCode: () => {},
  clearPageCode: () => {},
  projectRoles: [],
  setProjectRoles: () => {},
})

export default EnvContext

export function useEnvContext() {
  return useContext(EnvContext)
}

export function usePageCode(): [
  number | undefined,
  (error: any | number) => void,
  () => void
] {
  const [code, setCode] = React.useState<number>()
  const setPageCode = React.useCallback(error => {
    if (typeof error === 'number') {
      setCode(error)
    } else if (error && error.data && error.data.code) {
      setCode(error.data.code)
    }
  }, [])
  const clearPageCode = React.useCallback(() => {
    setCode(undefined)
  }, [])
  return [code, setPageCode, clearPageCode]
}

const defaultUserApi = '/accountTest/api/account/curAppletUser'

export function useCurrentUser() {
  // eslint-disable-next-line no-underscore-dangle
  const data = (window as any).__INITIAL_MODEL_DATA || {}
  const [user, setUser] = useState<AppletUserVO>(data.user)

  useEffect(() => {
    const { pathname } = window.location
    const userApi =
      // eslint-disable-next-line no-underscore-dangle
      `${defaultUserApi}?appletCode=${(window as any).__DEV_APPLET_CODE}`

    async function getCurrentUser() {
      try {
        const userResp = await window
          .fetch(userApi, XMLConfig)
          .then(resp => resp.json())
        if (userResp.code === AuthCode.SERVER_UNAUTHORIZED) {
          throw new Error('请登录')
        }
        setUser(userResp.data)
      } catch (e) {
        goToLogin()
        // message.error('接口调用失败，请检查服务端是否正常.', 10)
      }
    }

    const isLoginPage = /\/signin/.test(pathname)

    if (process.env.NODE_ENV === 'development' && !isLoginPage && !user) {
      getCurrentUser()
    }
  }, [user])

  return user || {}
}
