// libs
import React from 'react'
import { Helmet } from 'react-helmet'

// context
import { useEnvContext } from '../../context'

function Head({ title }: { title?: string }) {
  const { projectName } = useEnvContext()
  let str = ''

  if (title) {
    str = title
  }

  if (title && projectName) {
    str += ' · '
  }

  if (projectName) {
    str += projectName
  }

  return <Helmet title={str} />
}

export default Head
