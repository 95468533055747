// lib
import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import qs from 'query-string'

// components
import Head from '../components/Head'
import SignContainer from '../components/SignContainer'

// constants
import Code from '../constants/code'

// styles
import styles from './Signin.module.less'

// request
import request from '../request'

const { REACT_APP_APPLET_PREFIX: appletPrefix, NODE_ENV } = process.env

const isDev = NODE_ENV === 'development'

const goPage = () => {
  const { originalUrl = appletPrefix } = qs.parse(window.location.search)
  window.location.replace(originalUrl as string)
}

const SignIn = () => {
  const [form] = Form.useForm()

  const onSubmit = async () => {
    const values = await form.validateFields()
    try {
      const resp = await request.login({ code: values.code, pwd: values.pwd })
      const { data } = resp
      if (data.code === 0) {
        goPage()
      }
    } catch ({ data }) {
      if (
        data.code === Code.SERVER_OPERATE_REJECT &&
        data.message === '账号已经登录'
      ) {
        goPage()
      }
    }
  }

  const renderForm = () => {
    return (
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="code"
          rules={[{ required: true, message: '请输入用户名' }]}
        >
          <Input
            prefix={<UserOutlined className={styles['prefix-icon']} />}
            placeholder="请输入用户名"
          />
        </Form.Item>
        <Form.Item
          name="pwd"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input
            prefix={<LockOutlined className={styles['prefix-icon']} />}
            type="password"
            placeholder="请输入密码"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className={styles.button}
        >
          登录
        </Button>
      </Form>
    )
  }

  return (
    <React.Fragment>
      <Head title="登录" />
      <SignContainer
        form={renderForm()}
        footer={<a href={`${isDev ? '' : appletPrefix}/signup`}>免费注册</a>}
      />
    </React.Fragment>
  )
}

export default SignIn
