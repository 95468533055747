import Render from './Render'
import App from '../$$signin'
import '../styles/main.less'

Render(App)

// webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('../$$signin', () => {
    // if you are using harmony modules ({modules:false})
    // render(App)
    // in all other cases - re-require App manually
    // eslint-disable-next-line
    // Render(require('../login').default)
    Render(App)
  })
}
